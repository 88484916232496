<template>
  <a-modal width="60%" :label-col="4" :wrapper-col="14" :visible="open" @ok="onOk" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>选择运费模板</b>
    </a-divider>
    <a-card :bordered="false">
      <add-freight ref="freightDialog" @getList="getList"></add-freight>
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="模板名称">
                <a-input v-model="queryParam.name" placeholder="请输入模板名称" allow-clear @pressEnter="handleQuery"/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="计费方式">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('运费模板.计费方式')" style="width: 100%" v-model="queryParam.type" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.FreightTypeEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-row :gutter="10" class="mb8">
        <a-col :span="1.5">
          <a-button
            plain
            type="primary"
            icon="el-icon-user"
            @click="addFreight"
          >新建运费模板</a-button>
        </a-col>
      </a-row>
      <!-- 数据展示 -->
      <a-table
        :scroll="{ x: '130%' }"
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, type: 'radio', onChange: onSelectChange}"
        :pagination="false"
        :bordered="tableBordered"
      >
        <div slot="appoint" slot-scope="text"> {{ text ? '开启' : '关闭' }} </div>
        <span slot="createTime" slot-scope="text, record">
            {{ parseTime(record.createTime) }}
          </span>
        <span slot="type" slot-scope="text, record">
           <custom-dict-tag :options="customDict.FreightTypeEnum" :value="record.status"/>
          </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-modal>
</template>

<script>
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import Editor from "@/components/Editor/Editor";
import { tableMixin } from '@/store/table-mixin'
import * as logistics from "@/api/logistics";
import addFreight from './add_freight.vue'


export default {
  name: 'RadioFreightSelectForm',
  props: {},
  components: {
    Editor,
    CustomDictTag,
    addFreight
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      pageSizeOptions: ['1', '20', '50', '100'],
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      open: false,
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: undefined,
        name: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [{
        title: 'ID',
        dataIndex: 'id',
        width: 60,
        type: 'text'
      }, {
        title: '模板名称',
        dataIndex: 'name',
        width: 180,
        type: 'number'
      }, {
        title: '计费方式',
        dataIndex: 'type',
        width: 100,
        scopedSlots: { customRender: 'type' }
      }, {
        title: '指定包邮',
        dataIndex: 'appoint',
        width: 100,
        scopedSlots: { customRender: 'appoint' }
      }, {
        title: '排序',
        dataIndex: 'sort',
        width: 60,
        type: 'number'
      }, {
        title: '添加时间',
        dataIndex: 'createTime',
        width: 150,
        type: 'time'
      }
      ],
    };
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict']),
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.resetQuery()
    },
    /**
     *
     * @param id
     */
    openList(id) {
      if (id) {
        this.selectedRowKeys = [id];
      }
      this.getList()
      this.open = true;
    },
    onOk() {
      //判断有没有选择,拿到列表里选择的
      this.open = false
      this.$emit('freightSelect', this.selectedRows[0])
      this.resetQuery()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.resetQuery()
    },
    addFreight() {
      this.$refs.freightDialog.clear()
      this.$refs.freightDialog.handleShow()
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询明星列表 */
    getList() {
      this.loading = true;
      logistics.shippingTemplatesList(this.queryParam).then(response => {
        if (response.success) {
          this.list = response.data.records
          this.total = response.data.total;
          this.loading = false;
        }
      })
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.selectedRowKeys= [];
      this.selectedRows= [];
      this.queryParam = {
        type: undefined,
        name: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
  }
}
</script>
